import Image from "next/image"

const EssaWidget = ({ type }) => {
    const isMobile = type === 'mobile' ? true : false
  return (
    <div className="essa-main-container">
        <a target="_blank" href="ESSA-certificate.pdf">
            <Image
                src={'/images/ESSA-logo-purple-on-white.png'}
                alt='ESSA logo'
                width={!isMobile ? 130 : 160}
                height={!isMobile ? 25 : 30}
            />
        </a>
    </div>
  )
}

export default EssaWidget
